import React from 'react';

import { navigate } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import { Consumer, Provider } from '../../components/Tabs/Context';
import { getQueryParams } from '../../helpers/helper-methods';
import ActivityPage from '../../views/activity-page';
import Arrow from '../../assets/img/icons/ic-chevron-right-light-gray.svg';

import styles from './index.module.scss';

function Acitvity({ location }) {
  const { search } = location;
  const { id } = getQueryParams(search);

  return (
    <Layout>
      <div className="container">
        <Provider>
          <Consumer>
            {() => (
              <ActivityPage
                id={id}
                goBackButton={(
                  <button
                    onClick={() => navigate(-1)}
                    className={`btn ${styles.buttonBack}`}
                    type="button"
                  >
                    <Arrow className={styles.buttonBackArrow} />
                    Go Back
                  </button>
                )}
                buttonsContainerClasses={styles.headerButtonsContainer}
                bookActivityButtonWrapperClasses={styles.bookActivityButtonWrapper}
              />
            )}
          </Consumer>
        </Provider>
      </div>
    </Layout>
  );
}

export default Acitvity;
